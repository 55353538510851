import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import itemsList from "../../assets/JsonData/items-list.json";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { getSales, saleInvoice } from "../../app/salesSlice";
import { useDispatch, useSelector } from "react-redux";

const nowDate =  new Date();
const _fromDate =  new Date();
//const startDate = moment(_fromDate.setDate(1)).format("YYYY-MM-DD")

const salesTableHead = [
  { label: "Date", name: "selling_date" },
  { label: "Item Code", name: "item_code" },
  { label: "Description", name: "item_description" },
  { label: "Quantity", name: "qty" },
  { label: "Package", name: "package" },
  { label: "Rate", name: "rate" },  
  { label: "Amount", name: "amount" },
  { label: "Per", name: "unit_name" },
  { label: "Reference", name: "invoice_no" },
  "action",
];


const Sales = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  const [formValues, setFormValues] = useState({fromDate: moment(_fromDate.setDate(1)).format("YYYY-MM-DD"), toDate: moment(nowDate).format("YYYY-MM-DD")});
  const [selectedItem, setSelectedItem] = useState({});
  const [salesDataList, setSalesDataList] = useState([]);
  const [salesInvoiceData, setSalesInvoiceData] = useState(null);

  const tableHead = [
    { label: "Item Name", name: "item_description" },
    { label: "Item Code", name: "item_code" },
    { label: "Amount", name: "amount" },
    { label: "Package", name: "package" },
    { label: "Quantity", name: "qty" }
  ];

  const dispatch = useDispatch();

  const { error, status, list } = useSelector(
    ({ sales }) => sales.get
  );

  const { error:invoiceError, status:invoiceStatus, data } = useSelector(
    ({ sales }) => sales.invoice
  );
  
  
  useEffect(() => {
    dispatch(getSales(formValues));     
  }, [dispatch]);

  useEffect(() => {
   if(invoiceStatus == 'idle' && data){
    setOpenDetail(true)
   }    
  }, [invoiceStatus,data]);

  useEffect(() => {
    
    if(list && list.length > 0){   
      let newList = [];
      for (let index = 0; index < list.length; index++) {
        let newObj = {
          ...list[index],
          selling_date:moment(list[index]?.selling_date).format("YYYY-MM-DD"),
          package: list[index].package ? list[index].package : list[index].unit_name
        }
        newList.push(newObj)    
        //console.log('newObj',newObj)   
      }
      setSalesDataList(newList);
    }   
  }, [list]);

  const handleRequestClose = () => {
    setOpenDetail(false)
  };

  const dialogOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <span>Invoice Number</span>&nbsp;&nbsp;
            <span><b>{data?.saleHeader?.invoice_no}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Amount</span>&nbsp;&nbsp;
            <span><b>{Number(data?.saleHeader?.amount).toLocaleString()}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Date</span>&nbsp;&nbsp;
            <span><b>{moment(data?.saleHeader?.date).format("YYYY-MM-DD")}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Items</span>&nbsp;&nbsp;
            <span><b>{data?.saleHeader?.items}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Customer</span>&nbsp;&nbsp;
            <span><b>{data?.saleHeader?.customer}</b></span>
          </div>
          <br /> <br />
          <div className="col-sm-12 col-md-12 col-12">
          </div>
          <br /> <br />
          <div className="col-12">
            {data?.saleDetails.length > 0 ? (
              <Table
                columns={tableHead}
                data={data?.saleDetails}
                downloadFileName={"Sale Receipt"}
              />
            ) : 
            (
              <MySpinner size={35} color="#10d4d2" />
            )}
          </div>

        </div>
      </>
    );
  };

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
     
    
  };

  const viewItem = (item) => {
    //alert(JSON.stringify(item))
    dispatch(saleInvoice({invoice_no:item?.invoice_no}))
  }

 



  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Sales</h2>
       
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {salesDataList.length>0 ? (
                <Table
                  columns={salesTableHead}
                  data={salesDataList}
                  actions={[
                    {
                      action: "Details",
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                  ]}
                  downloadFileName={"Available Items"}
                />
              ) : (
                <MySpinner size={35} color="#10d4d2" />
              )}
            </div>
          </div>
        </div>
      </div>
      <MyDialog
        title={"Sale Receipt"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={"Sale Receipt"}
        content={() => dialogOpen()}
        //hideSaveButton={showSideEffect ? false: true}
        //propBackgroundColor="#367588"
      />
    </div>
  );
};

export default Sales;
