import React, { useEffect } from "react";
import "./layout.css";

import { ToastContainer } from "react-toastify";

//*Routes

//*GENERAL
import Login from "../pages/auth/Login";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/users/Users";
import Reports from "../pages/reports/Reports";

//*DEV

//* NOTFOUND
import NotFound from "../common/notFound.jsx";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setAppColor, setAppMode } from "../app/themeSlice";
import MainLayout from "../common/main";
import GeneralLayout from "../common/general";
import Items from "../pages/items/Items";
import Pos from "../pages/pos/Pos";
import Sales from "../pages/sales/Sales";
import Resources from "../pages/resources/Resources";
import Roles from "../pages/roles/Roles";
import Workflow from "../pages/workflow/Workflow";
import Products from "../pages/products/Products";
import Accounts from "../pages/accounts/Accounts";
import Register from "../pages/auth/Register";
import Confirm from "../pages/auth/Confirm";
import Business from "../pages/businesses/Businesses";
import Loans from "../pages/loans/Loans";

const Layout = () => {
  // const themeReducer = useSelector((state) => state.theme);
  const { activeSession } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  // localStorage.clear();

  useEffect(() => {
    const themeClass = localStorage.getItem("themeMode", "theme-mode-light");
    const colorClass = localStorage.getItem("colorMode", "theme-mode-light");
    dispatch(setAppMode(themeClass));
    dispatch(setAppColor(colorClass));
  }, [dispatch]);

  // useEffect(() => {
  //   if (activeSession === null) {
  //     navigate("/login");
  //   }
  // }, [activeSession, navigate]);

  let location = useLocation();

  let isUserAuthenticated =
    activeSession && activeSession.hasOwnProperty("token") ? true : false;

  const publicRoute = ({
    component: Component,
    layout: Layout,
    path,
    ...props
  }) => {
    return (
      <Route
        path={path}
        element={
          <Layout>
            <Component {...props} />
          </Layout>
        }
      />
    );
  };

  const privateRoute = ({
    component: Component,
    layout: Layout,
    path,
    ...props
  }) => {
    if (/* isUserAuthenticated */ true) {
      <Navigate to={"/login"} state={{ from: location }} />;
    }
    return (
      <Route
        path={path}
        element={
          <Layout>
            <Component {...props} />
          </Layout>
        }
      />
    );
  };

  // const selectedTheme = useSelector((state) => state.theme.mode);

  return (
    <>
      <ToastContainer
      // theme={selectedTheme === "theme-mode-light" ? "dark" : "light"}
      />
      <Routes>
        {/* public */}
        {publicRoute({
          path: "/login",
          component: Login,
          layout: GeneralLayout,
        })}
        {publicRoute({
          path: "/register",
          component: Register,
          layout: GeneralLayout,
        })}
        {publicRoute({
          path: "/confirm_registration",
          component: Confirm,
          layout: GeneralLayout,
        })}
        {/* dashboard */}
        {privateRoute({
          component: Dashboard,
          layout: MainLayout,
          path: "/",
        })}
         {/* items */}
         {privateRoute({
          component: Items,
          layout: MainLayout,
          path: "/items",
        })}

        {/* businesses */}
        {privateRoute({
          component: Business,
          layout: MainLayout,
          path: "/businesses",
        })}

         {/* loans */}
         {privateRoute({
          component: Loans,
          layout: MainLayout,
          path: "/loans",
        })}

        {/* private */}
        {privateRoute({
          component: Users,
          layout: MainLayout,
          path: "/staff",
        })}
        {privateRoute({
          component: Pos,
          layout: MainLayout,
          path: "/pos",
        })}
        {privateRoute({
          component: Sales,
          layout: MainLayout,
          path: "/sales",
        })}
        {privateRoute({
          component: Accounts,
          layout: MainLayout,
          path: "/accounts",
        })}
        {privateRoute({
          component: Reports,
          layout: MainLayout,
          path: "/reports",
        })}
        {privateRoute({
          component: Resources,
          layout: MainLayout,
          path: "/resources",
        })}
        {privateRoute({
          component: Roles,
          layout: MainLayout,
          path: "/roles",
        })}
        {privateRoute({
          component: Workflow,
          layout: MainLayout,
          path: "/workflows",
        })}
        {privateRoute({
          component: Products,
          layout: MainLayout,
          path: "/products",
        })}
        
        {/* Not Found*/}
        <Route
          element={
            isUserAuthenticated ? (
              <GeneralLayout>
                <NotFound />
              </GeneralLayout>
            ) : (
              <MainLayout>
                <NotFound />
              </MainLayout>
            )
          }
          path="/404"
        />
        <Route path="*" element={<Navigate to="404" />} />
      </Routes>
    </>
  );
};

export default Layout;
