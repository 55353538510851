import { adminServiceApi } from "../services";

export const userAuth = async (query = null) => {
  const reqOpts = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("user/login",query, reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const switchBusiness = async (query = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("users/switch_institution",query, reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const resetPassword = async (query = null) => {
  const reqOpts = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("user/reset_password",query, reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserList = async (query = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("users", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const registerUser = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("users/register", payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const selfRegisterUser = async (payload = null) => {
  const reqOpts = {
    headers: {
      //Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    let fullnames = String(payload.fullname).split(' ');
    payload.f_name = fullnames && fullnames.length > 0 ? fullnames[0] : null;
    payload.m_name = fullnames && fullnames.length > 1 ? fullnames[1] : null;
    payload.l_name = fullnames && fullnames.length > 2 ? fullnames[2] : null;
    delete payload.fullname;
    delete payload.confirm_password;
    const response = await adminServiceApi.post("user/self_register", payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const confirmUserRegistration = async (payload = null) => {
  const reqOpts = {
    headers: {
      //Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("user/confirm_registration", payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const resendCode = async (payload = null) => {
  const reqOpts = {
    headers: {
      //Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("user/resend_code", payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putUser = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("users/"+payload?.id, payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};