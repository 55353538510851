import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import itemsList from "../../assets/JsonData/items-list.json";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { salesReport, expensesReport, disposingReport, stockingReport, expiringReport } from "../../app/reportsSlice";
import { useDispatch, useSelector } from "react-redux";
import AppTabs from "../../components/appTabs/appTabs";
import Sections from "../../components/dashbord/sections";



const Reports = () => {

  const tabs = [
    {
      title: "Revenue",
    },
    {
      title: "Items Expiring",
    },
    {
      title: "Others",
    },
  ];

  const itemsTableHead = [
    { label: "Period", name: "time_bound" },
    { label: "Amount", name: "amount" }
  ];

  const expiringTableHead = [
    { label: "Item Name", name: "item_description" },
    { label: "Item Code", name: "item_code" },
    { label: "Quantity", name: "qty" },
    { label: "Amount", name: "amount" },
    { label: "Purchase Date", name: "buy_date" },
    { label: "Expiry Date", name: "expiry_date" },
  ];


  const [activeTab, setActiveTab] = useState(0);
  let [showRevenue, setShowRevenue] = useState(false);
  let [showExpenses, setShowExpenses] = useState(false);
  let [showDisposed, setShowDisposed] = useState(false);
  let [showOther, setShowOther] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [expiringDataList, setExpiringDataList] = useState([]);



  const dispatch = useDispatch();

  const { error: salesError, status: salesStatus, list: salesList } = useSelector(
    ({ reports }) => reports.sales.get
  );

  const { error: expensesError, status: expensesStatus, list: expensesList } = useSelector(
    ({ reports }) => reports.expenses.get
  );

  const { error: disposingError, status: disposingStatus, list: disposingList } = useSelector(
    ({ reports }) => reports.disposing.get
  );

  const { error: stockingError, status: stockingStatus, list: stockingList } = useSelector(
    ({ reports }) => reports.stocking.get
  );

  const { error: expiringError, status: expiringStatus, list: expiringList } = useSelector(
    ({ reports }) => reports.expiring.get
  );

  useEffect(() => {
    if (activeTab === 0) {
      setShowRevenue(true);
      setShowDisposed(false);
      setShowOther(false);
      dispatch(salesReport())
      dispatch(expensesReport())
      dispatch(disposingReport())
      dispatch(stockingReport())
    } else if (activeTab === 1) {
      setShowRevenue(false);
      setShowDisposed(true);
      setShowOther(false);
      dispatch(expiringReport())
    } else {
      setShowRevenue(false);
      setShowDisposed(false);
      setShowOther(true);
    }

  }, [dispatch, activeTab]);

  useEffect(() => {
    if(expiringStatus === 'idle' && !expiringError && expiringList){
      let expiringArray = [];
      for (let index = 0; index < expiringList.length; index++) {
        expiringArray.push(
          { item_code: expiringList[index]?.item_code, 
            item_description: expiringList[index]?.item_description,
            qty: Number(expiringList[index]?.qty).toLocaleString(),
            amount: Number(expiringList[index]?.qty * expiringList[index]?.buying_price).toLocaleString(),
            expiry_date: moment(expiringList[index]?.expiry_date).format("YYYY-MM-DD"),
            buy_date: moment(expiringList[index]?.buy_date).format("YYYY-MM-DD"),
          })
        
      }
      setExpiringDataList(expiringArray);

    }
  },[expiringList,expiringStatus,expiringError])

  const handleRequestClose = () => {

  };



  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));


  };


  const handleChangeTabs = (index) => {
    setActiveTab(index);
  };


  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Reports</h2>

      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <AppTabs
                tabs={tabs}
                activeTab={activeTab}
                handleTabChange={handleChangeTabs}
              />
              {showRevenue && <div className="row">
                <div className="col-sm-12 col-md-6 col-3">
                <h4>Sales</h4><br/>
                  {salesList && salesList.length > 0 ? (
                    <Sections
                      columns={itemsTableHead}
                      data={salesList}
                    />
                  ) : showRevenue ? (
                    <MySpinner size={35} color="#10d4d2" />
                  ) : (<></>)}
                </div>
                <div className="col-sm-12 col-md-6 col-3">
                <h4>Expenses</h4><br/>
                  {expensesList && expensesList.length > 0 ? (
                    <Sections
                      columns={itemsTableHead}
                      data={expensesList}
                    />
                  ) : showRevenue ? (
                    <MySpinner size={35} color="#10d4d2" />
                  ) : (<></>)}
                </div>
                <div className="col-sm-12 col-md-6 col-3">
                <h4>Disposing</h4><br/>
                  {disposingList && disposingList.length > 0 ? (
                    <Sections
                      columns={itemsTableHead}
                      data={disposingList}
                    />
                  ) : showRevenue ? (
                    <MySpinner size={35} color="#10d4d2" />
                  ) : (<></>)}
                </div>
                <div className="col-sm-12 col-md-6 col-3">
                  <h4>Stocking</h4><br/>
                  {stockingList && stockingList.length > 0 ? (
                    <Sections
                      columns={itemsTableHead}
                      data={stockingList}
                    />
                  ) : showRevenue ? (
                    <MySpinner size={35} color="#10d4d2" />
                  ) : (<></>)}
                </div>
              </div>}
              {showDisposed && <div className="row">
                <div className="col-sm-12 col-md-12 col-12">
                <Table
                columns={expiringTableHead}
                data={expiringDataList}
                downloadFileName={"Items Expiring Soon"}
              />
                </div>
              </div>}

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Reports;
