import React from "react";
// import { Button } from '@mui/material';

import "./footer.css";

const today = new Date().getFullYear();
const Footer = () => {
  return <small>&copy; WD - {today}</small>;
};

export default Footer;
