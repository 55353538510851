import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import itemsList from "../../assets/JsonData/items-list.json";
import { Flip, toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { getSales, saleInvoice } from "../../app/salesSlice";
import { getBusiness, addBusiness,updateBusiness } from "../../app/businessesSlice";
import { useDispatch, useSelector } from "react-redux";
import Cards from "../../components/cards/Cards";
import { switchUserBusiness } from "../../app/authSlice";
import { set } from "date-fns";
import validate from "validate.js";
import { businessSchema } from "../../common/validators";
import { getInstitutionCategories } from "../../app/commonSlice";

const nowDate =  new Date();
const _fromDate =  new Date();
//const startDate = moment(_fromDate.setDate(1)).format("YYYY-MM-DD")

const salesTableHead = [
  { label: "Date", name: "selling_date" },
  { label: "Item Code", name: "item_code" },
  { label: "Description", name: "item_description" },
  { label: "Quantity", name: "qty" },
  { label: "Package", name: "package" },
  { label: "Rate", name: "rate" },  
  { label: "Amount", name: "amount" },
  { label: "Per", name: "unit_name" },
  { label: "Reference", name: "invoice_no" },
  "action",
];


const Business = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);

  let [hideSaveButton, setHideSaveButton] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [dataList, setDataList] = useState([]);
  const [salesInvoiceData, setSalesInvoiceData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [instCategories, setInstCategories] = useState([]);

  const tableHead = [
    { label: "Item Name", name: "item_description" },
    { label: "Item Code", name: "item_code" },
    { label: "Amount", name: "amount" },
    { label: "Package", name: "package" },
    { label: "Quantity", name: "qty" }
  ];

  const dispatch = useDispatch();

  const { error:switchError, status:switchStatus } = useSelector(
    ({ auth }) => auth.post
  );

  const { error, status, list } = useSelector(
    ({ businesses }) => businesses.get
  );

  const { error:postError, status:postStatus } = useSelector(
    ({ businesses }) => businesses.post
  );

  const { error:putError, status:putStatus } = useSelector(
    ({ businesses }) => businesses.put
  );

  const { error:instCategoriesError, status:instCategoriesStatus, list: instCategoriesList } = useSelector(
    ({ common }) => common.institutionCategories.get
  );
  
  
  useEffect(() => {
    dispatch(getBusiness());     
    dispatch(getInstitutionCategories());     
  }, [dispatch]);


  useEffect(() => {
    if(instCategoriesStatus === "loading"){

    }else if(instCategoriesStatus === "idle" && instCategoriesList){
      
      let categoryArray = [];
      for (let index = 0; index < instCategoriesList.length; index++) {
        categoryArray.push({value:instCategoriesList[index]["id"],label:instCategoriesList[index]["name"]})        
      }
      setInstCategories(categoryArray);
    }
  }, [instCategoriesStatus,instCategoriesError,instCategoriesList]);

  useEffect(() => {
    if(postStatus == 'loading' && !postError){
      setHideSaveButton(true)
    }else
   if(postStatus == 'idle' && !postError){
    setHideSaveButton(false)
    setFormValues({})
    setOpenForm(false)
    dispatch(getBusiness())
   }else{
    setHideSaveButton(false)
   }    
  }, [postStatus,postError]);

  useEffect(() => {
    if(putStatus == 'loading' && !postError){
      setHideSaveButton(true)
    }else
    if(putStatus == 'idle' && !putError){
      setHideSaveButton(false)
     setEdit(false)
     setFormValues({})
     setOpenForm(false)
     dispatch(getBusiness())
    }else{
      setHideSaveButton(false)
     }     
   }, [putStatus,putError]);

  useEffect(() => {
    if(switchStatus == 'idle' && !switchError){
     
    }    
   }, [switchStatus,switchError]);

  useEffect(() => {
    
    if(list && list.length > 0){   
      setDataList(list);
    }   
  }, [list]);

  const handleRequestClose = () => {
    setOpenForm(false)
    setEdit(false)
    setFormValues({})
  };

  const dialogOpen = () => {
    return null;
  };

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationErrors = validate(
      formValues,
      businessSchema
    );

    if(validationErrors){
      setErrors(validationErrors);      
    }else{
    if(edit === true){
      dispatch(updateBusiness(formValues))
    }else{
      dispatch(addBusiness(formValues))
    }
  }
    
    //toast.success(JSON.stringify(formValues));
     
    
  };

  const formBusiness = (item) => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="name"
              size="small"
              label="Business Name"
              value={formValues?.name}
              inputType="text"
              onChange={handleChange}
              error={errors["name"] ? true : false}
              helperText={errors["name"] ? errors.name[0] : null}
            />
          </div>

          {instCategories.length ? (
            <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="inst_cat"
            size="small"
            inputType="select"
            value={formValues.inst_cat}
            defaultValue={instCategories[0]["value"]}
            label="Category"
            listLabel="Category"
            listValue="inst_cat"
            list={instCategories}
            onChange={handleChange}
            error={errors["inst_cat"] ? true : false}
            helperText={errors["inst_cat"] ? errors.inst_cat[0] : null}
          />
          </div>
      ) : (
        ""
      )}

        </div>
      </>
    );
  };

  const switchBusiness = (item) => {
    const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    if(activeSession && activeSession.data.inst_id != item?.id){
     dispatch(switchUserBusiness({purpose:'switch',inst_id:item?.id}))
    }else{
      setEdit(true)
      setFormValues({name:item?.name,id:item?.id})
      setOpenForm(true)
      toast.success("You are already on this business", {
        autoClose: 1300,
        transition: Flip,
        hideProgressBar: true,
      })
      // alert(activeSession.data.inst_id)
      //alert('Can not Change')
      //dispatch(switchUserBusiness({purpose:'switch',inst_id:item?.id}))
    }
    //alert(JSON.stringify(item))
    //dispatch(saleInvoice({invoice_no:item?.invoice_no}))
  }




  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Businesses</h2>
        <div style={{ alignContent: "right" }}>
        &nbsp;
          {
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={"New Business"}
            onClick={() => setOpenForm(true)}
            propBackgroundColor={"#367588"}
          />
          }
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {dataList.length>0 ? (                
                <div className="row">
                  {dataList.map((item, index) => (              
                  <div className="col-4" key={index} onClick={() => {switchBusiness(item)}} style={{cursor:"pointer"}}>
                    <Cards
                      type="normal"
                      icon={null}
                      count={null}
                      title={item.name}
                    />
                  </div>
                ))}
              </div>                
              ) : (
                <MySpinner size={35} color="#10d4d2" />
              )}
            </div>
          </div>
        </div>
      </div>
      <MyDialog
        title={edit === true ? "Edit Business Name" : "New Business"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={edit === true ? "SAVE CHANGES" :"SAVE"}
        content={() => formBusiness()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={!hideSaveButton ? false: true}
        propBackgroundColor="#367588"
      />
    </div>
  );
};

export default Business;
