import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import itemsList from "../../assets/JsonData/items-list.json";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { getItems } from "../../app/itemsSlice";
import { getUsers } from "../../app/userSlice";
import { addPos } from "../../app/posSlice";
import { useDispatch, useSelector } from "react-redux";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import { getCategory, getUnitMeasures } from "../../app/commonSlice";
import { getHandovers, getHandoversDetails, addHandover, updateHandover } from "../../app/handoversSlice";
import "./pos.css";
import KeyPad from "./Keypad";
import CalaculatorResult from "./CalaculatorResult";
import { postHandover } from "../../api/handoverAPI";
import { hasAccess } from "../../common/validators";



const Pos = () => {
  let [openForm, setOpenForm] = useState(false);
  let [canAddSale, setCanAddSale] = useState(false);
  let [canAddShift, setCanAddShift] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [quantity, setQuantity] = useState(0);
  let [boxQuantity, setBoxQuantity] = useState(0);
  let [priceRate, setPriceRate] = useState(0);
  let [totalAmount, setTotalAmount] = useState(0);
  let [totalAmountPurchased, setTotalAmountPurchased] = useState(0);
  let [itemsPurchasedCount, setItemsPurchasedCount] = useState(0);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  let [showHandoverForm, setShowHandoverForm] = useState(false);
  let [isPendingHandover, setIsPendingHandover] = useState(false);


  const [formValues, setFormValues] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);;
  const [itemDataList, setItemDataList] = useState([]);
  const [itemsHanded, setItemsHanded] = useState([]);
  const [userDataList, setUserDataList] = useState([]);
  const [itemsPurchasedList, setItemPurchasedList] = useState([]);
  const [categoryDataList, setCategoryDataList] = useState([]);
  const [measuresDataList, setMeasuresDataList] = useState([]);

  // const itemsTableHead = [
  //   { label: "Item Code", name: "item_code" },
  //   { label: "Description", name: "item_description" },
  //   { label: "Quantity", name: "qty" },
  //   { label: "Package", name: "package" },
  //   { label: "Rate", name: "rate" },
  //   { label: "Per", name: "unit_name" },
  //   { label: "Discount", name: "discount" },
  //   { label: "Amount", name: "amount" },
  //   "action",
  // ];


  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

  const itemsTableHead = [
    { label: "Item Name", name: "item_description" },
    { label: "Item Code", name: "item_code" },
    { label: "Package", name: "package" },
    { label: "Quantity", name: "qty" },
    { label: "Units In", name: "unit_name" },
    { label: "Discount", name: "discount" },
    { label: "Amount", name: "amount" },
    { label: "Rate", name: "rate" },
    "action"
  ];


  // item_code: selectedItem?.item_code,
  // item_description: selectedItem?.item_description,
  // item_id: selectedItem?.item_id,
  // is_retail: formValues && formValues?.retail_whole === "Whole Sale" ? "N" : "Y",
  // qty: quantity,
  // package: boxQuantity > 0 ? "Box" : selectedItem?.unit_name,
  // unit_of_measure: boxQuantity > 0 ? 0 : selectedItem?.unit_of_measure,
  // unit_name: selectedItem?.unit_name,
  // amount: boxQuantity > 0 ? totalAmount * selectedItem?.items_per_box : totalAmount,
  // rate: totalAmount / quantity,
  // discount: 0,
  // selling_date: new Date(),
  // entry_by: 1,
  // entry_date: new Date(),
  // inst_id: 1

  const itemsTableHeadShift = [
    { label: "Item Name", name: "item_description" },
    { label: "Item Code", name: "item_code" },
    { label: "Units In", name: "unit_name" },
    { label: "Packs Quantity", name: "packs" },
    { label: "Items Quantity", name: "qty" },
    { label: "Items Per Pack", name: "items_per_box" },
    { label: "Equivalent Amount", name: "amount" },
  ];

  
  const shiftReasons = [
    { label: "Normal Timetable", value: "Normal" },
    { label: "Emergency", value: "Emergency" },
  ];

  

  const handoverForm = () => {
    //alert(isPendingHandover)
    return (
      <>
        <div className="row">
        <div className="col-12" style={{backgroundColor:"#e1e1e16c", padding:"20px",margin:"15px",borderRadius:"5px"}}>
        <div className="row">
          {isPendingHandover && listHandovers.length > 0 && (
            <div className="col-sm-12 col-md-6 col-6">
              <span>Shift From</span>&nbsp;&nbsp;
              <span><b>{listHandovers[0]?.handover_name}</b></span>
            </div>
          )}

          {isPendingHandover && listHandovers.length > 0 && (
            <div className="col-sm-12 col-md-6 col-6">
              <span>Shift To</span>&nbsp;&nbsp;
              <span><b>{listHandovers[0]?.takeover_name}</b></span>
            </div>
          )}
          {isPendingHandover && listHandovers.length > 0 && (
            <div className="col-sm-12 col-md-6 col-6">
              <span>Remarks</span>&nbsp;&nbsp;
              <span><b>{listHandovers[0]?.remarks}</b></span>
            </div>)}
          {isPendingHandover && listHandovers.length > 0 && (
            <div className="col-sm-12 col-md-6 col-6">
              <span>Status</span>&nbsp;&nbsp;
              <span><b>{listHandovers[0]?.status}</b></span>
            </div>
          )}
          
           {isPendingHandover && listHandovers.length > 0 && listHandovers[0]?.takeover_by === userData.data?.id && (<div className="col-sm-12 col-md-6 col-6" style={{paddingTop:"20px"}}>
            <Inputs
              name="remarks"
              size="small"
              text="Reject"
              inputType="button"
              onClick={(e) => rejectShift(e)}
            />
          </div>
          )}
          {isPendingHandover && listHandovers.length > 0 && listHandovers[0]?.takeover_by === userData.data?.id && (<div className="col-sm-12 col-md-6 col-6" style={{paddingTop:"20px"}}>
            <Inputs
              name="remarks"
              size="small"
              text="Accept"
              inputType="button"
              onClick={(e) => acceptShift(e)}
              propBackgroundColor="#367588"
            />
          </div>
          )}
          </div>
          </div>
          
          {!isPendingHandover && listHandovers.length <= 0 && (<div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="remarks"
              size="small"
              label="Shift Reason"
              value={formValues.remarks}
              inputType="select"
              defaultValue={shiftReasons[0]["value"]}
              listLabel="Shift Reason"
              listValue="remarks"
              list={shiftReasons}
              onChange={handleChange}
            />
          </div>
          )}
          {!isPendingHandover && listHandovers.length <= 0 && userDataList && userDataList.length > 0 ? (
            <div className="col-sm-12 col-md-6 col-6">
              <Inputs
                name="takeover_by"
                size="small"
                inputType="select"
                value={formValues.takeover_by}
                defaultValue={userDataList[0]["value"]}
                label="Switch With"
                listLabel="Switch With"
                listValue="takeover_by"
                list={userDataList}
                onChange={handleChange}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-sm-12 col-md-12 col-12">
            <Table
              columns={itemsTableHeadShift}
              data={itemsHanded}
              downloadFileName={"Items Handed Over"}
            />
          </div>
        </div>
      </>
    );
  };


  let _date = new Date();
  let cno = _date.getFullYear() + "" + _date.getMonth() + "" + _date.getDate() + "" + _date.getHours() + "" + _date.getMinutes() + "" + _date.getSeconds() + "" + _date.getMilliseconds();


  const retailWhole = [
    { label: "Retail", value: "Retail", selected: true },
    { label: "Whole Sale", value: "Whole Sale", selected: false }
  ]

  const dispatch = useDispatch();

  const { error, status, list } = useSelector(
    ({ items }) => items.get
  );

  const { error: posErrorPost, status: posStatusPost } = useSelector(
    ({ pos }) => pos.post
  );


  const { error: errorCategories, status: statusCategories, list: listCategories } = useSelector(
    ({ common }) => common.categories.get
  );

  const { error: errorMeasures, status: statusMeasures, list: listMeasures } = useSelector(
    ({ common }) => common.unitMeasures.get
  );

  const { error: errorUsers, status: statusUsers, list: listUsers } = useSelector(
    ({ users }) => users.get
  );

  const { error: errorHandovers, status: statusHandovers, list: listHandovers } = useSelector(
    ({ handovers }) => handovers.get
  );

  const { error: errorHandoverDetails, status: statusHandoverDetails, list: listHandoverDetails } = useSelector(
    ({ handovers }) => handovers.details
  );

  const { error: handoverErrorPost, status: handoverStatusPost } = useSelector(
    ({ handovers }) => handovers.post
  );

  const { error: handoverErrorPut, status: handoverStatusPut } = useSelector(
    ({ handovers }) => handovers.put
  );

  useEffect(() => {
    // setSelectedItem(null)
    // setIsPendingHandover(false)
    isAddAccess();
    isAddShiftAccess();
    dispatch(getItems());
    dispatch(getCategory());
    dispatch(getUnitMeasures());
  }, [dispatch]);


  useEffect(() => {
    if (status === "idle" && list) {
      let lovItems = [];
      let handedItems = [];
      for (let index = 0; index < list.length; index++) {
        if (list[index].qty_bal > 0) {
          lovItems.push({ label: list[index]["item_description"] + " - " + list[index]["item_code"] + " - " + list[index]["unit_name"], value: list[index] })
        }
        handedItems.push(
          {
            item_description: list[index]["item_description"],
            item_code: list[index]["item_code"],
            packs: list[index]["qty_bal"] / list[index]["items_per_box"],
            unit_name: list[index]["unit_name"],
            items_per_box: list[index]["items_per_box"],
            qty: list[index]["qty_bal"],
            amount: list[index]["qty_bal"] * list[index]["prev_buying_price"],
          }
        )
      }
      setItemDataList(lovItems);
      setItemsHanded(handedItems);
    }
  }, [list, status]);

  useEffect(() => {

    if (statusUsers === "idle" && listUsers) {
      let lovUsers = [];
      for (let index = 0; index < listUsers.length; index++) {
        if(listUsers[index]?.id != userData.data?.id && (listUsers[index]?.status === "A" || listUsers[index]?.status === "H")){
          lovUsers.push({ label: listUsers[index]["f_name"] + " - " + listUsers[index]["m_name"] + " - " + listUsers[index]["l_name"], value: listUsers[index]["id"] })
        }
        
      }
      setUserDataList(lovUsers);
    }
  }, [listUsers, statusUsers]);


  useEffect(() => {
    if (statusMeasures === "loading") {

    } else if (statusMeasures === "idle") {
      let measureArray = [{ value: 0, label: "Box" }];
      for (let index = 0; index < listMeasures.length; index++) {
        measureArray.push({ value: listMeasures[index]?.id, label: listMeasures[index]?.name })
      }
      setMeasuresDataList(measureArray);
    }
  }, [statusMeasures, listMeasures]);

  useEffect(() => {
    if (selectedItem) {
      //alert(JSON.stringify(selectedItem))
      if (formValues && formValues?.retail_whole === "Whole Sale") {
        setPriceRate(selectedItem?.w_selling_price)
      } else {
        setPriceRate(selectedItem?.selling_price)
      }

      setQuantity(0);
    }

  }, [selectedItem])

  useEffect(() => {
    //alert(JSON.stringify(selectedItem))
    //alert(quantity)
    setTotalAmount(priceRate * quantity);

  }, [quantity]);

  useEffect(() => {

    if (selectedItem && totalAmount > 0) {
      //alert(" = "+totalAmount * selectedItem?.items_per_box)
      let newSale = {
        item_code: selectedItem?.item_code,
        item_description: selectedItem?.item_description,
        item_id: selectedItem?.item_id,
        is_retail: formValues && formValues?.retail_whole === "Whole Sale" ? "N" : "Y",
        qty: quantity,
        package: boxQuantity > 0 ? "Box" : selectedItem?.unit_name,
        unit_of_measure: boxQuantity > 0 ? 0 : selectedItem?.unit_of_measure,
        unit_name: selectedItem?.unit_name,
        amount: boxQuantity > 0 ? totalAmount * selectedItem?.items_per_box : totalAmount,
        rate: totalAmount / quantity,
        discount: 0,
        selling_date: new Date(),
        entry_by: 1,
        entry_date: new Date(),
        inst_id: 1
      };
      let purchasedList = itemsPurchasedList.length > 0 ? itemsPurchasedList : [];

      let alreadyAvailable = false;


      newSale.invoice_no = cno;
      for (let index = 0; index < purchasedList.length; index++) {
        // alert(itemsPurchasedList.length)  
        if (boxQuantity === 0 && selectedItem.qty_bal > 0 && selectedItem.qty_bal >= parseInt(newSale.qty) && purchasedList[index]["item_code"] === selectedItem.item_code) {
          purchasedList[index] = newSale;
          alreadyAvailable = true;
        }

        if (boxQuantity > 0 && selectedItem.qty_bal > 0 && selectedItem.qty_bal >= parseInt(quantity * selectedItem.items_per_box) && purchasedList[index]["item_code"] === selectedItem.item_code) {
          purchasedList[index] = newSale;
          alreadyAvailable = true;
        }

      }

      //alert(selectedItem.qty_bal)

      if (boxQuantity === 0 && alreadyAvailable == false && selectedItem.qty_bal > 0 && parseInt(selectedItem.qty_bal) >= parseInt(newSale.qty)) {
        purchasedList.push(newSale);
      }

      if (boxQuantity > 0 && alreadyAvailable == false && selectedItem.qty_bal > 0 && parseInt(selectedItem.qty_bal) >= parseInt(quantity * selectedItem.items_per_box)) {
        purchasedList.push(newSale);
      }

      setItemPurchasedList(purchasedList);

      let amountPurchased = 0;
      if (itemsPurchasedList.length > 0) {
        setItemsPurchasedCount(itemsPurchasedList.length);
        for (let index = 0; index < itemsPurchasedList.length; index++) {
          amountPurchased = amountPurchased + itemsPurchasedList[index]?.amount;
        }
        setTotalAmountPurchased(amountPurchased);
      }

    }

  }, [totalAmount]);

  const isAddShiftAccess = () => {
    setCanAddShift(hasAccess('shift','add'))
  }

  const isViewShiftAccess = () => {
    return hasAccess('shift','view');
  }

  const isAddAccess = () => {
    setCanAddSale(hasAccess('sales','add'))
  }

  const handleClickCalculator = (button) => {
    //setPriceRate(250); 
    //setQuantity(button); 

    if (button === "=" || button === "+" || button === "*" || button === "-" || button === ")" || button === "(" || button === "." || button === "/") {
      //this.calculate();
    }

    else if (button === "C") {
      reset();
    }

    else if (button === "CE") {
      backspace();
    }

    else {
      let newQuantity = quantity + button;
      if (formValues && formValues?.unit_of_measure === 0) {
        // let boxQuantity = newQuantity * (selectedItem.items_per_box ? selectedItem.items_per_box : 1);
        setQuantity(newQuantity)
        setBoxQuantity(newQuantity)
      } else {
        setQuantity(newQuantity);
        setBoxQuantity(0)
      }

    }
  };

  const reset = () => {
    setQuantity(0);
  };

  const backspace = () => {
    if (formValues && formValues.unit_of_measure === 0) {
      // let toRemove = selectedItem.items_per_box ? selectedItem.items_per_box : 100;
      // //alert(String(toRemove).length)
      // let newQuantity = quantity.slice(0, (-1*String(toRemove).length));
      // setQuantity(newQuantity);
      setQuantity(0);
    } else {
      let newQuantity = quantity.slice(0, -1);
      setQuantity(newQuantity);
    }

  };

  const handleRequestClose = () => {
    setHideSaveButton(false)
    setFormValues({});
    setShowHandoverForm(false);
    // setEdit(false);
  };

  const removeItem = (item) => {
    setHideSaveButton(false)
    //alert(JSON.stringify(item))
    let array = itemsPurchasedList;
    let itemsCount = itemsPurchasedList.length;
    let toUpdateTotal = totalAmountPurchased;
    const index = array.indexOf(item);
    if (index > -1) { // only splice array when item is found
      array.splice(index, 1); // 2nd parameter means remove one item only
    }
    setItemPurchasedList(array);
    setItemsPurchasedCount(itemsCount > 0 ? itemsCount-1 : 0);
    setTotalAmountPurchased(toUpdateTotal > item?.amount ? toUpdateTotal-item?.amount : 0)
  }

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
    //alert(JSON.stringify(event.target))

    if (event.target.name === "unit_of_measure") {
      setSelectedItem(null);
      setQuantity(0);
      let lovItems = [];
      for (let index = 0; index < list.length; index++) {
        if (list[index].qty_bal > 0 && event.target.value != 0 && list[index]["unit_of_measure"] === event.target.value) {
          lovItems.push({ label: list[index]["item_description"] + " (" + list[index]["item_code"] + ") - " + list[index]["items_per_box"] + " " + list[index]["unit_name"] + " per box", value: list[index] })
        }

        if (list[index].qty_bal > 0 && event.target.value === 0) {
          lovItems.push({ label: list[index]["item_description"] + " (" + list[index]["item_code"] + ") - " + list[index]["items_per_box"] + " " + list[index]["unit_name"] + " per box", value: list[index] })
        }
      }
      setItemDataList(lovItems);
    }

    if (event.target.name === "item_code") {
      setSelectedItem(event.target.value)
    }
  };

  const handleShift = () => {
    setShowHandoverForm(true);
    setItemPurchasedList([])
    setTotalAmountPurchased(0)
    setTotalAmount(0)
    setItemsPurchasedCount(0)
    setSelectedItem(null)
    dispatch(getUsers())
    dispatch(getHandovers({ status: "W",takeover_by: userData.data?.id }))
  }

  const acceptShift = (e) => {
    e.preventDefault();
    let updatedShift = {
      id: listHandovers[0]?.id,
      status: "A"
    }
    //setIsPendingHandover(false)
    dispatch(updateHandover(updatedShift))
  }

  const rejectShift = (e) => {
    e.preventDefault();
    //alert(listHandovers[0]?.id);
    let updatedShift = {
      id: listHandovers[0]?.id,
      status: "X"
    }
    //setIsPendingHandover(false)
    dispatch(updateHandover(updatedShift))
  }

  const submitShift = (e) => {
    e.preventDefault();
    if (formValues.takeover_by != userData.data?.id) {
      let newHandover = {
        ...formValues,
        handover_date: new Date()
      };

      let handoverWithRequest = {
        handoverRequest: newHandover,
        handoverDetails: itemsHanded
      }

      //console.log('handoverWithRequesthandoverWithRequest',handoverWithRequest)

      //toast.success("Shift request submited");
      dispatch(addHandover(handoverWithRequest))
    } else {
      toast.warn("Please provide the right Receiver");
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (totalAmountPurchased > 0 && itemsPurchasedList.length) {
      dispatch(addPos(itemsPurchasedList))
    } else {
      toast.warn("Please provide Items");
    }

  };

  useEffect(() => {
    if (posStatusPost === "loading") {
      setHideSaveButton(true)
    }else
    if (posStatusPost === "posted") {
      setHideSaveButton(false)
      setItemsHanded([])
      setItemPurchasedList([])
      setTotalAmountPurchased(0)
      setTotalAmount(0)
      setItemsPurchasedCount(0)
      setSelectedItem(null)
    }

  }, [posErrorPost, posStatusPost]);

  useEffect(() => {
    if (handoverStatusPost === "idle") {
      // setItemsHanded([])
      // setFormValues({})
      setItemPurchasedList([])
      setTotalAmountPurchased(0)
      setTotalAmount(0)
      setItemsPurchasedCount(0)
      setSelectedItem(null)
    }

  }, [handoverErrorPost, handoverStatusPost]);


  useEffect(() => {
    if (handoverStatusPut === "idle") {
      setItemsHanded([])
      // setFormValues({})
      setIsPendingHandover(false)
      dispatch(getItems())
      setItemPurchasedList([])
      setTotalAmountPurchased(0)
      setTotalAmount(0)
      setItemsPurchasedCount(0)
      setSelectedItem(null)
    }

  }, [handoverErrorPut, handoverStatusPut]);


  useEffect(() => {
    if (statusHandovers === "idle" && listHandovers && listHandovers.length >= 1) {
      // setItemsHanded([])
      // setFormValues({})
      setItemPurchasedList([])
      setTotalAmountPurchased(0)
      setTotalAmount(0)
      setItemsPurchasedCount(0)
      setSelectedItem(null)

      //alert(listHandovers.length)
      if(listHandovers && listHandovers.length >= 1){
        setIsPendingHandover(true)
        dispatch(getHandoversDetails({ request_id: listHandovers[0]?.id}))
      }
      
    }

  }, [listHandovers, statusHandovers]);

  useEffect(() => {
    if (statusHandoverDetails == 'idle') {
      let handedItems = [];
      for (let index = 0; index < listHandoverDetails.length; index++) {
        handedItems.push(
          {
            item_description: listHandoverDetails[index]["item_description"],
            item_code: listHandoverDetails[index]["item_code"],
            packs: listHandoverDetails[index]["packs"],
            unit_name: listHandoverDetails[index]["unit_name"],
            items_per_box: listHandoverDetails[index]["items_per_box"],
            qty: listHandoverDetails[index]["qty"],
            amount: listHandoverDetails[index]["amount"],
          }
        )





      }
      setItemsHanded(handedItems);
    }

  }, [listHandoverDetails, statusHandoverDetails]);

  return (
    <div>
      {/* <div className="ez-page-action">
        <h2 className="page-header">POS</h2>
       
      </div> */}

      <div className="row">
        <div className="col-12">
          <div className="card" style={{ backgroundColor: "#e0e0e0" }}>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-8">
                  <div className="row" style={{ height: "auto" }}>
                    <div className="col-sm-12 col-md-4 col-4">
                      <Inputs
                        name="retail_whole"
                        size="small"
                        label="Sale Category"
                        inputType="select"
                        defaultValue={retailWhole[0]["value"]}
                        listLabel="Sale Category"
                        listValue="retail_whole"
                        list={retailWhole}
                        onChange={handleChange}

                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-4">
                      {measuresDataList ? (
                        <Inputs
                          name="unit_of_measure"
                          size="small"
                          label="Package"
                          inputType="select"
                          defaultValue={measuresDataList[0]?.value}
                          listLabel="Package"
                          listValue="unit_of_measure"
                          list={measuresDataList}
                          onChange={handleChange}

                        />
                      ) : <></>}

                    </div>

                    <div className="col-sm-12 col-md-4 col-4">
                      <Inputs
                        name="item_code"
                        size="small"
                        label="Search Item"
                        // inputType="autocomplete"
                        // listValue={itemDataList}
                        // onChange={(event,value) =>{handleChange(value)}}
                        // options={itemDataList}
                        // // value={formValues.item_code}

                        inputType="select"
                        value={formValues.item_code}
                        defaultValue={itemDataList[0]}
                        listLabel="Buy Price PeSearch Item"
                        listValue="item_code"
                        list={itemDataList}
                        onChange={handleChange}

                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-12">
                      <br />
                      <br />
                      <p>ITEMS PURCHASED <b style={{ float: "right", color: "green" }}>TOTAL PURCHASED  {totalAmountPurchased}</b> </p>
                      <br />
                    </div>

                    <div className="col-sm-12 col-md-12 col-12">
                      {itemsPurchasedList.length > 0 ? (<Table
                        columns={itemsTableHead}
                        data={itemsPurchasedList}
                        actions={[
                          {
                            action: "Remove",
                            onClick: (item) => {
                              removeItem(item)
                            },
                          }
                        ]}
                        downloadFileName={"Purchased Items"}
                      />
                      ) : (
                        <MySpinner size={35} color="#10d4d2" />
                      )}
                    </div>
                  </div>
                  <br />
                  <br />


                </div>
                <div className="col-sm-12 col-md-6 col-4">
                  <div className="col-sm-12 col-md-12 col-12">
                    <Inputs
                      name="qty"
                      size="small"
                      label=""
                      value={quantity}
                      inputType="text"
                      disabled={false}
                      onChange={null}
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-12">
                    <KeyPad _onClick={(e) => handleClickCalculator(e.target.name)} />
                    <CalaculatorResult result={{ totalPurchased: totalAmountPurchased, itemCounts: itemsPurchasedCount }} />
                  </div>
                  <br />

                </div>
                <div className="col-sm-12 col-md-6 col-6" style={{ textAlign: "left" }}>
                  {canAddShift === true && (
                  <Inputs
                    color="success"
                    className={`bx-burst-hover`}
                    size="small"
                    inputType="button"
                    type="submit"
                    text={"Exchange Shift"}
                    onClick={handleShift}
                  // propBackgroundColor={"#367588"}
                  />)}
                </div>
                <div className="col-sm-12 col-md-6 col-6" style={{ textAlign: "right" }}>
                  {canAddSale === true ?
                  <Inputs
                    color="success"
                    className={`bx-burst-hover`}
                    size="small"
                    inputType="button"
                    type="submit"
                    text={"Save This"}
                    onClick={handleSubmit}
                    propBackgroundColor={"#367588"}
                    hideSaveButton={hideSaveButton}
                  />:<></>}
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      <MyDialog
        title={"Exchange Shift"}
        width="md"
        onClose={handleRequestClose}
        open={showHandoverForm}
        text={!isPendingHandover && showHandoverForm ? "Send Request" : userData.data?.id === listHandovers[0]?.takeover_by ? "Accept Request" : "Remind Receiver"}
        content={() => handoverForm()}
        submit={(e) => !isPendingHandover ? submitShift(e) : {}}
        hideSaveButton={(isPendingHandover === false && showHandoverForm) ? false : true}
        propBackgroundColor="#367588"
      />
    </div>
  );
};

export default Pos;
