import React, { useEffect,useCallback } from "react";
import "../layout/layout.css";

import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/header/Header";
import { ToastContainer } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";
import { setAppColor, setAppMode } from "../app/themeSlice";
import Particles from 'react-particles';
import { loadFull } from "tsparticles";

import dawa from "../assets/images/syrups.svg";
import tablet from "../assets/images/tablet.svg";
import syringe from "../assets/images/syringe.svg";
import bottle from "../assets/images/bottle.svg";


const Layout = (props) => {
  const { children } = props;
  const themeReducer = useSelector((state) => state.theme);
  // const { activeSession } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  // localStorage.clear();

  useEffect(() => {
    const themeClass = localStorage.getItem("themeMode", "theme-mode-light");
    const colorClass = localStorage.getItem("colorMode", "theme-mode-light");
    dispatch(setAppMode(themeClass));
    dispatch(setAppColor(colorClass));
  }, [dispatch]);

  // const selectedTheme = useSelector((state) => state.theme.mode);

  
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    //await console.log(container);
  }, []);


const myCanvas = <Particles 
id="tsparticles"
init={particlesInit}
loaded={particlesLoaded}
options={{
  background: {
    color: {
      value: "#367588",
    },
  },
  fpsLimit: 320,
  "particles": {
     
      "line_linked": {
          "enable": true
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        directions: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 6,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 10,
      },
      opacity: {
        value: 0.5,
      },
      "shape": {
          "type": [
              "image"
          ],
          "image": [
              {
                  "src": dawa,
                  "height": 20,
                  "width": 23
              },
              {
                  "src": bottle,
                  "height": 20,
                  "width": 20
              },
              {
                  "src": tablet,
                  "height": 20,
                  "width": 20
              },
              {
                "src": syringe,
                "height": 20,
                "width": 20
              }

          ]
      },
      "color": {
          "value": "#ffffff"
      },
      "size": {
          "value": 30,
          "random": false,
          "anim": {
              "enable": true,
              "speed": 4,
              "size_min": 10,
              "sync": false
          }
      }
  },
  "retina_detect": false
}} />


  return (
    <>
    
      <ToastContainer
      // theme={selectedTheme === "theme-mode-light" ? "dark" : "light"}
      />

      <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
        {/* <Sidebar {...props} /> */}
      
        <div className="ez-layout-content">
          <Header />
         
          <div className="ez-content-main">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
