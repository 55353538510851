import React from "react";
import "./cards.css";
import Inputs from "../inputs/Inputs";

const Cards = (props) => {
  if (props.type === "normal") {
    return (
      <div className="ez-card" id="vertical">
        {props.icon && props.title ? (
          <>
            <div className="ez-card-icon-vertical">
              <i className={props.icon}></i>
            </div>
            <div className="ez-card-info">
              {/* <h4>{props.title}</h4> */}
              <span>{props.title}</span>
            </div>
          </>
        ) : (
          <div className="ez-card-icon-vertical">
             <div className="ez-card-info">
              <span>{props.title}</span>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        {props.renderData ? (
          <div className="card">
            <div className="card-body">
              {props.renderData.map((item, index) => (
                <>
                  <div className="ez-auct-card">
                    {item.tenure} - {item.type}
                  </div>
                  <div className="ez-auct-body">
                    <div className="row">
                      <div className="ez-auct-items col-12">
                        <span>Auction No: {item.auctNo}</span>
                        <span>
                          Issue: <b>{item.issue}</b>
                        </span>
                        {item.isin ? <span>ISIN: {item.isin}</span> : ""}
                        <span>
                          Type: <b>{item.type}</b>
                        </span>
                        <span>Tenure: {item.tenure}</span>
                        <span>Maturity Date: {item.maturity}</span>
                        <span>
                          Competative Amount:{" "}
                          {Intl.NumberFormat().format(item.compPrice)} TZS
                        </span>
                        {item.compPrice ? (
                          <span>
                            Non-Competative Amount:{" "}
                            {Intl.NumberFormat().format(item.noncompPrice)} TZS
                          </span>
                        ) : (
                          ""
                        )}
                        <span>Auction Date: {item.auctDate}</span>
                        {item.dirtyPrice ? (
                          <span>Dirty Price: {item.dirtyPrice}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="ez-btn-holder">
                      <Inputs
                        color="primary"
                        size="small"
                        inputType="button"
                        text="Submitted Bids"
                        type="submit"
                        onClick={""}
                      />
                      <Inputs
                        color="success"
                        size="small"
                        inputType="button"
                        type="submit"
                        text="Bid Now"
                        onClick={""}
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
};

export default Cards;
