import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../../assets/images/logo.png";
import moment from "moment";
// import { useSelector } from "react-redux";

const DownloadPDFDataTable = ({
  fileName = "",
  data = [],
  columns = [],
  toDate = null,
  fromDate = null,
}) => {
  // const { activeSession } = useSelector(({ auth }) => auth);

  const Table = ({ children }) => (
    <View
      style={{
        marginTop: 8,
        marginBottom: 8,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </View>
  );

  const TableRow = ({ children }) => (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "right",
        borderBottom: "1px solid #e5e5e5",
      }}
    >
      {children}
    </View>
  );

  const TableRowNoLine = ({ children }) => (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "right",
      }}
    >
      {children}
    </View>
  );

  const TableHeader = ({ children }) => (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#367588",
      }}
    >
      {children}
    </View>
  );

  const TableCell = ({ children }) => (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 5,
        paddingVertical: 10,
        fontSize: 8,
        textAlign: "left",
      }}
    >
      <Text>{children}</Text>
    </View>
  );

  const TableCellRightAlign = ({ children }) => (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 5,
        paddingVertical: 10,
        fontSize: 8,
        textAlign: "right",
      }}
    >
      <Text>{children}</Text>
    </View>
  );

  const TableCellHeader = ({ children }) => (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 5,
        paddingVertical: 10,
        fontSize: 10,
        textAlign: "left",
      }}
    >
      <Text>{children}</Text>
    </View>
  );

  const TableWhiteSpace = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 20,
        }}
      ></View>
    );
  };

  const userData = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;


  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },

    image: {
      height: 80,
      width: 150,
      marginVertical: 15,
      marginHorizontal: 100,
    },
    imageLogo: {
      alignSelf: "center",
      height: 75,
      width: 75,
      marginBottom: 10,
    },
    cellBackground: {
      backgroundColor: "#dddddd",
    },
    subtitle: {
      textAlign: "center",
      marginBottom: 5,
      fontSize: 12,
    },

    padded: {
      padding: 20,
    },

    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 20,
      fontWeight: 800,
      textAlign: "center",
      textDecoration: "none",
      marginBottom: 10,
    },
    downloadLink: {
      textDecoration: "none",
      color: "unset",
    },
  });

  const BuildPDF = () => {
    return (
      <Document>
        <Page orientation="landscape" style={styles.padded}>
          <Image style={styles.imageLogo} src={logo} />
          <Text style={styles.title}>{String(userData.data?.name).toLocaleUpperCase()}</Text>
          <Text style={styles.title}>{fileName.toUpperCase()}</Text>
          <Table>
            <TableRowNoLine>
              <TableCell>
                {fromDate !== null && toDate !== null && (
                  <>
                    {" "}
                    Date Range: From {fromDate} to {toDate}
                  </>
                )}
              </TableCell>
              <TableCellRightAlign>
                Run by: {userData.data?.f_name +" "+userData.data?.l_name}
              </TableCellRightAlign>
            </TableRowNoLine>
            <TableRowNoLine>
              <TableCell></TableCell>
              <TableCellRightAlign>
                Date: {moment().format("dddd, MMMM Do YYYY")} Time:{" "}
                {moment().format("h:mm:ss a")}
              </TableCellRightAlign>
            </TableRowNoLine>
          </Table>
          <TableWhiteSpace />
          {/* <Text style={styles.subtitle}>{fileName.toUpperCase()}</Text> */}
          <Table>
            <TableHeader>
              <TableCellHeader key={`header-sn`}>S/N</TableCellHeader>
              {columns.map((column, index) => (
                <TableCellHeader key={`header-${index}`}>
                  {column.label}
                </TableCellHeader>
              ))}
            </TableHeader>

            {data.map((row, rowIndex) => (
              <TableRow key={`row-${rowIndex}`}>
                <TableCell key={`row-${rowIndex}-col-sn`}>
                  {rowIndex + 1}
                </TableCell>
                {columns.map((col, colIndex) => (
                  <TableCell key={`row-${rowIndex}-col-${colIndex}`}>
                    {row[col.name] ?? ""}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Table>
        </Page>
      </Document>
    );
  };

  return (
    <PDFDownloadLink
      style={styles.downloadLink}
      document={<BuildPDF />}
      fileName={`${fileName}.pdf`}
    >
      {({ blob, url, loading, error }) => (
        <>
          {!loading && <div style={styles.downloadLink}>Download PDF</div>}
          {loading && (
            <div
              style={{ width: "100px" }}
              className="flex-row justify-content-center align-items-center"
            >
              <CircularProgress size={11} color="primary" />
            </div>
          )}
        </>
      )}
    </PDFDownloadLink>
  );
};

export default DownloadPDFDataTable;
