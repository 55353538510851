import { adminServiceApi } from "../services";

export const getPOSaleList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  // query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("pos", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const postPos = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    
  let _date = new Date();
  let cno = _date.getFullYear() + "" + _date.getMonth() + "" + _date.getDate() + "" + _date.getHours() + "" + _date.getMinutes() + "" + _date.getSeconds() + "" + _date.getMilliseconds();
    let newCno = query.inst_id ? query.inst_id + ""+cno: cno;
    for (let index = 0; index < query.length; index++) {
      query[index]["invoice_no"] = newCno;      
    }

    const response = await adminServiceApi.post("pos/add",query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putPos = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("pos/"+query?.id,query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};