import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { useDispatch, useSelector } from "react-redux";
import { addWorkflow, updateWorkflow,getWorkflow } from "../../app/workflowSlice";
import { getActions, getComponents } from "../../app/commonSlice";

const itemsTableHead = [
  { label: "Name", name: "name" },
  "action",
];


const Workflow = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);  
  let [hideSaveButton, setHideSaveButton] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [itemDataList, setItemDataList] = useState([]);
  const [componentsDataList, setComponentsDataList] = useState([]);
  const [actionsDataList, setActionsDataList] = useState([]);

  

  const dispatch = useDispatch();
  
  const { error, status, list } = useSelector(
    ({ workflow }) => workflow.get
  );

  const { error:postError, status:postStatus } = useSelector(
    ({ workflow }) => workflow.post
  );

  const { error:putError, status:putStatus } = useSelector(
    ({ workflow }) => workflow.put
  );

  const { get:getActionsData } = useSelector(
    ({ common }) => common.actions
  );

  const { get:getComponentsData } = useSelector(
    ({ common }) => common.components
  );

 

  const handleRequestClose = () => {
    setFormValues({});
    setOpenForm(false);
    setOpenDetail(false);  
    setEdit(false);
  };

  const viewItem = (item) => {
    if (item) {

      setViewDetailsSelected(true)

      //setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const editItem = (item) => {
    if (item) {
      setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };


  const formOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="name"
              size="small"
              label="Workflow Stage"
              value={formValues.name}
              inputType="text"
              disabled={false}
              onChange={handleChange}
            />
          </div>
          
        </div>
      </>
    );
  };


  const dialogOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <span><b>Workflow Stage</b></span>&nbsp;&nbsp;
            <span>{selectedItem?.name}</span>           
          </div>
          
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    //alert(JSON.stringify(event.target))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    if(edit){
      let newItem = {
        "id": selectedItem?.id,
        "name": formValues?.name,
      }
      dispatch(updateWorkflow(newItem));
    }else{
      let newItem = {
        "name": formValues?.name,
      }
      dispatch(addWorkflow(newItem));
    }    
    
  };

  

  useEffect(() => {
    dispatch(getWorkflow());  
    // dispatch(getActions());    
    // dispatch(getComponents());    
  }, [dispatch]);
  

  useEffect(() => {
    if(postStatus === "loading" && !edit){
      setHideSaveButton(false);
    }else if(postStatus === "idle"){
      dispatch(getWorkflow());   
      setHideSaveButton(false);
    }
  }, [postStatus]);

  useEffect(() => {
    if(putStatus === "loading" && edit){
      setHideSaveButton(false);
    }else if(putStatus === "idle"){
      dispatch(getWorkflow());   
      setHideSaveButton(false);
    }

  }, [putStatus]);


  useEffect(() => {
    if(status === "loading"){

    }else if(status === "idle"){
      setItemDataList(list);
    }

  }, [status,list]);

 

  useEffect(() => {
    if(getComponentsData.status === "loading"){

    }else if(getComponentsData.status === "idle"){
      let componentsArray = [];
      for (let index = 0; index < getComponentsData.list.length; index++) {
        componentsArray.push({value:getComponentsData.list[index]?.name,label:getComponentsData.list[index]?.name})        
      }
      setComponentsDataList(componentsArray);
    }
  }, [getComponentsData]);

 

  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Available Workflows</h2>
         &nbsp;
         <div style={{alignContent:"right"}}>
        <Inputs
          color="success"
          className={`bx-burst-hover`}
          size="small"
          inputType="button"
          type="submit"
          text={"New Workflow"}
          onClick={() => setOpenForm(true)}
        />
        &nbsp;
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {itemDataList.length>0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Update",
                      onClick: (item) => {
                        editItem(item);
                      },
                    }
                  ]}
                  downloadFileName={"Available Workflows"}
                />
              ) : (
                <MySpinner size={35} color="#10d4d2" />
              )}
            </div>
          </div>
        </div>
      </div>

      <Detaildialog
        title={"Workflow Details"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={!edit ? "Save" : "Update"}
        content={() => dialogOpen()}
        
      />

      <MyDialog
        title={"Update Workflow Details"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
      />

    </div>
  );
};

export default Workflow;
