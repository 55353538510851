import React, { Component } from 'react';

const CalaculatorResult = ({result}) => {

  return (
    <div className="result">
      <p>ITEMS &nbsp;  {result?.itemCounts}</p>
      <p>TOTAL AMOUNT &nbsp;  {result?.totalPurchased}</p>
    </div>
  )

}

export default CalaculatorResult;