import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import itemsList from "../../assets/JsonData/items-list.json";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { addItems, updateItems } from "../../app/itemsSlice";
import { useDispatch, useSelector } from "react-redux";
import AppTabs from "../../components/appTabs/appTabs";
import { getDisposedItems,updateDisposedItems } from "../../app/disposedItemsSlice";
import { getExpenses,updateExpense,addExpense } from "../../app/expensesSlice";
import { hasAccess } from "../../common/validators";



const Accounts = () => {

  const tabs = [
    {
      title: "Expenses",
    },
    {
      title: "Disposed Items",
    },
    {
      title: "Ledger",
    },
  ];

  const itemsTableHead = [
    { label: "Item Name", name: "item_name" },
    { label: "Item Code", name: "item_code" },
    { label: "Quantity", name: "qty" },
    { label: "Equivalent Amount", name: "amount" },
    { label: "Disposed Date", name: "destroy_date" },
    "action",
  ];

  const expenseTableHead = [
    { label: "Date", name: "expense_date" },
    { label: "Amount", name: "amount" },
    { label: "Area", name: "area" },
    { label: "Status", name: "status" },
    "action",
  ];

  const areaDataList = [
    {label:"Electricity Bill",value:"1"},
    {label:"Water Bill",value:"2"},
    {label:"Wages",value:"3"},
    {label:"Legal",value:"4"},
    {label:"Stock Taking",value:"5"},
    {label:"Tax",value:"6"},
    {label:"Rent",value:"7"},
    {label:"Stationary",value:"8"},
    {label:"Miscellaneous",value:"9"},
  ]

  const statuses = [
    {label:"Active",value:"A"},
    {label:"Cancelled",value:"X"}
  ]

  const [activeTab, setActiveTab] = useState(0);
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [canAddSale, setCanAddSale] = useState(false);
  let [canEditSale, setCanEditSale] = useState(false);
  
  let [edit, setEdit] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  let [showExpenses, setShowExpenses] = useState(false);
  let [showDisposed, setShowDisposed] = useState(false);
  let [showLedger, setShowLedger] = useState(false);
  const [formValues, setFormValues] = useState({expense_date: new Date()});
  const [selectedItem, setSelectedItem] = useState({});

  

  const dispatch = useDispatch();

  const { error, status, list } = useSelector(
    ({ disposedItems }) => disposedItems.get
  );

  const { error:postError, status:postStatus } = useSelector(
    ({ disposedItems }) => disposedItems.post
  );

  const { error:putError, status:putStatus } = useSelector(
    ({ disposedItems }) => disposedItems.put
  );

  const { error:expensesError, status:expensesStatus, list:expensesList } = useSelector(
    ({ expenses }) => expenses.get
  );

  const { error:postErrorExpenses, status:postStatusExpenses } = useSelector(
    ({ expenses }) => expenses.post
  );

  const { error:putErrorExpenses, status:putStatusExpenses } = useSelector(
    ({ expenses }) => expenses.put
  );

  useEffect(() => {
    isAddAccess();
    isEditAccess();
    if(activeTab === 0){
      setShowExpenses(true);
      setShowDisposed(false);
      setShowLedger(false);
      dispatch(getExpenses())
    }else if(activeTab === 1){
      setShowExpenses(false);
      setShowDisposed(true);
      setShowLedger(false);
      dispatch(getDisposedItems())
    }else{
      setShowExpenses(false);
      setShowDisposed(false);
      setShowLedger(true);
    }
    
  }, [dispatch, activeTab]);


  useEffect(() => {
    if(putStatus === 'idle'){
      dispatch(getDisposedItems())
    }

  },[putStatus])

  useEffect(() => {
    if(postStatusExpenses === 'idle'){
      dispatch(getExpenses())
    }

  },[postStatusExpenses])

  
  useEffect(() => {
    if(putStatusExpenses === 'idle'){
      dispatch(getExpenses())
    }

  },[putStatusExpenses])
  

  const handleRequestClose = () => {
    setFormValues({});
    setOpenForm(false);
    setOpenDetail(false);   
    setEdit(false);
  };

  const handleReverseDisposal = (item) => {
    ///alert(JSON.stringify(item))
    let newItem = {
      ...item,
      status:'X',
      item_name:null
    };
    dispatch(updateDisposedItems(newItem))
  };

  const formOpen = () => {
    return (
      <>
        <div className="row">
         <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="expense_date"
              size="small"
              label="Date"
              value={formValues.expense_date}
              inputType="date"
              disabled={edit?true:false}
              onChange={(e)=>handleChange({target:{name:"expense_date",value:e}})}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="amount"
              size="small"
              label="Amount"
              value={formValues.amount}
              inputType="number"
              disabled={edit?true:false}
              onChange={handleChange}
            />
          </div>
         

{areaDataList.length ? (
        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="area"
            size="small"
            inputType="select"
            value={formValues.area}
            defaultValue={areaDataList[0]["area"]}
            label="Expense Area"
            listLabel="Expense Area"
            listValue="area"
            list={areaDataList}
            onChange={handleChange}
          />
        </div>
      ) : (
        ""
      )}

{statuses.length ? (
        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="status"
            size="small"
            inputType="select"
            value={formValues.status}
            defaultValue={statuses[0]["value"]}
            label="Status"
            listLabel="Status"
            listValue="status"
            list={statuses}
            onChange={handleChange}
          />
        </div>
      ) : (
        ""
      )}
           <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="remarks"
              size="small"
              label="Remarks"
              value={formValues.remarks}
              inputType="textarea"
              onChange={handleChange}
            />
          </div>
        </div>
      </>
    );
  };

  const editItem = (item) => {
    if (item) {
      setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };

  const isAddAccess = () => {
    setCanAddSale(hasAccess('expenses','add'))
  }

  const isEditAccess = () => {
   return hasAccess('expenses','edit')
  }

  const isReverseAccess = () => {
   return hasAccess('items','delete')
  }

  

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    if(edit){
      dispatch(updateExpense(formValues)) 
    }else{
      dispatch(addExpense(formValues)) 
    }
        
    
  };

  const handleChangeTabs = (index) => {
    setActiveTab(index);
  };



  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Accounts</h2>
        &nbsp;
         <div style={{alignContent:"right"}}>
           {canAddSale && showExpenses && (
              <Inputs
              color="success"
              className={`bx-burst-hover`}
              size="small"
              inputType="button"
              type="submit"
              text={"New Expense"}
              onClick={() => setOpenForm(true)}
              propBackgroundColor={"#367588"}
            />
           )}
       
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
            <AppTabs
              tabs={tabs}
              activeTab={activeTab}
              handleTabChange={handleChangeTabs}
            />
            <div className="col-sm-12 col-md-12 col-12">
            {showDisposed && list && list.length > 0 ? (
              <Table
                columns={itemsTableHead}
                data={list}
                actions={[
                  {
                    action: "Reverse",
                    renderCheck: isReverseAccess,
                    onClick: (item) => {
                      handleReverseDisposal(item);
                    },
                  },
                ]}
                downloadFileName={"Disposed Items"}
              />
            ) : showDisposed ?(
              <MySpinner size={35} color="#10d4d2" />
            ) : (<></>)}
          </div>
          <div className="col-sm-12 col-md-12 col-12">
            {showExpenses && expensesList && expensesList.length > 0 ? (
              <Table
                columns={expenseTableHead}
                data={expensesList}
                actions={[
                  {
                    action: "Edit",
                    renderCheck: isEditAccess,
                    onClick: (item) => {
                      editItem(item);
                    },
                  },
                ]}
                downloadFileName={"Expenses Made"}
              />
            ) : showExpenses ?(
              <MySpinner size={35} color="#10d4d2" />
            ) : (<></>)}
          </div>
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={!edit ?"Add New Expense":"Update Expense Details"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor={"#367588"}
      />
    </div>
  );
};

export default Accounts;
