import React, { useState,useEffect,useCallback } from "react";
import Inputs from "../../components/inputs/Inputs";
import logo from "../../assets/images/taiPOS.png";
import "./login.css";
import Footer from "../../components/footer/Footer";

import { registerself } from "../../app/userSlice";
import { useSelector,useDispatch } from "react-redux";
import Particles from 'react-particles';
import { loadFull } from "tsparticles";

import dawa from "../../assets/images/syrups.svg";
import tablet from "../../assets/images/tablet.svg";
import syringe from "../../assets/images/syringe.svg";
import bottle from "../../assets/images/bottle.svg";
import { registerSchema } from "../../common/validators";
import validate from "validate.js";
import { useNavigate } from "react-router-dom";
import { getInstitutionCategories } from "../../app/commonSlice";

const Register = () => {
  // const auth = useSelector((state) => state.auth);
  const [errors, setErrors] = useState([]);
  const [instCategories, setInstCategories] = useState([]);
  
  const [showResetButton, setShowResetButton] = useState(true);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {auth} = useSelector((state) => state.auth);  
  const { error, status, data } = useSelector(
    ({ users }) => users.post
  );
  const { error:instCategoriesError, status:instCategoriesStatus, list: instCategoriesList } = useSelector(
    ({ common }) => common.institutionCategories.get
  );


  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  useEffect(async () => {
    dispatch(getInstitutionCategories())
  }, [dispatch]);

  useEffect(() => {
    if(instCategoriesStatus === "loading"){

    }else if(instCategoriesStatus === "idle" && instCategoriesList){
      
      let categoryArray = [];
      for (let index = 0; index < instCategoriesList.length; index++) {
        categoryArray.push({value:instCategoriesList[index]["id"],label:instCategoriesList[index]["name"]})        
      }
      setInstCategories(categoryArray);
    }
  }, [instCategoriesStatus,instCategoriesError,instCategoriesList]);

  useEffect(() => {
    //alert(JSON.stringify(auth));
    if(auth?.data){
      localStorage.setItem('userData',JSON.stringify(auth?.data))
    }
  }, [auth]);

  useEffect(() => {
    if(status === 'loading'){
      setShowResetButton(false)
    }else
    if(status === 'posted' && error === null){
      setShowResetButton(true)
      // window.location.replace("/confirm_registration")
      navigate("/confirm_registration");
    }
    else
    if(error){
      setShowResetButton(true)
    }
  }, [status,error, data]);

  const backToLogin = (e) => {
    e.preventDefault();
    // window.location.replace("/login")
    navigate("/login");
  }

  const submit = (e) => {
    e.preventDefault();
    let validationErrors = validate(
      formValues,
      registerSchema
    );


    if(validationErrors){
      setErrors(validationErrors);      
    }else{
      dispatch(
        registerself(formValues)
      );
      //window.location.replace("/confirm_registration")
      
    }

  };

  
  const particlesInit = useCallback(async (engine) => {
    //console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    //await console.log(container);
  }, []);


const myCanvas = <Particles 
id="tsparticles"
init={particlesInit}
loaded={particlesLoaded}
options={{
  fpsLimit: 320,
  "particles": {
     
      "line_linked": {
          "enable": true
      },
      links: {
        color: "#367588",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        directions: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 1,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 30,
      },
      opacity: {
        value: 0.5,
      },
      "shape": {
          "type": [
              "image"
          ],
          "image": [
              {
                  "src": dawa,
                  "height": 10,
                  "width": 10
              },
              {
                  "src": bottle,
                  "height": 10,
                  "width": 10
              },
              {
                  "src": tablet,
                  "height": 10,
                  "width": 10
              },
              {
                "src": syringe,
                "height": 10,
                "width": 10
              }

          ]
      },
      "color": {
          "value": "#ffffff"
      },
      "size": {
          "value": 15,
          "random": false,
          "anim": {
              "enable": true,
              "speed": 4,
              "size_min": 10,
              "sync": false
          }
      }
  },
  "retina_detect": false
}} />


  //alert(localStorage.getItem('token'))
  return (
    <div className="register-page">
      <div style={{zIndex:"5 !íportant"}}>
      {myCanvas}
        </div>
       
      <div className="row">
        <div className="col-12">
          <img src={logo} alt="logo" height="80vh" />
          <div className="card" style={{backgroundColor:"#ffffff"}}>
            <h2 className="page-header">TaiPass</h2>
            <div
              className="card-body"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Inputs
                name="fullname"
                label="Full name"
                className="input-class"
                size="small"
                value={formValues.fullname}
                inputType="text"
                onChange={handleChange}
                error={errors["fullname"] ? true : false}
                helperText={errors["fullname"] ? errors.fullname[0] : null}
              />
              <br />
              <Inputs
                name="business_name"
                label="Business name"
                className="input-class"
                size="small"
                value={formValues.business_name}
                inputType="text"
                onChange={handleChange}
                error={errors["business_name"] ? true : false}
                helperText={errors["business_name"] ? errors.business_name[0] : null}
              />
              <br />
              {instCategories.length ? (
          <Inputs
            name="inst_cat"
            size="small"
            inputType="select"
            value={formValues.inst_cat}
            defaultValue={instCategories[0]["value"]}
            label="Category"
            listLabel="Category"
            listValue="inst_cat"
            list={instCategories}
            onChange={handleChange}
            error={errors["inst_cat"] ? true : false}
            helperText={errors["inst_cat"] ? errors.inst_cat[0] : null}
          />
      ) : (
        ""
      )}
              <br />
              <Inputs
                name="email"
                label="Email Address"
                className="input-class"
                size="small"
                value={formValues.email}
                inputType="text"
                onChange={handleChange}
                error={errors["email"] ? true : false}
                helperText={errors["email"] ? errors.email[0] : null}
              />
              <br />
              <Inputs
                name="mobile"
                label="Mobile number"
                className="input-class"
                size="small"
                value={formValues.mobile}
                inputType="text"
                onChange={handleChange}
                error={errors["mobile"] ? true : false}
                helperText={errors["mobile"] ? errors.mobile[0] : null}
              />              
              <br />
              <Inputs
                name="password"
                label="Password"
                className="input-class"
                size="small"
                value={formValues.password}
                inputType="password"
                onChange={handleChange}
                error={errors["password"] ? true : false}
                helperText={errors["password"] ? errors.password[0] : null}
              />
              <br />
              <Inputs
                name="confirm_password"
                label="Confirm Password"
                className="input-class"
                size="small"
                value={formValues.confirm_password}
                inputType="password"
                onChange={handleChange}
                error={errors["confirm_password"] ? true : false}
                helperText={errors["confirm_password"] ? errors.confirm_password[0] : null}
              />
              <br />
              {showResetButton && <Inputs
                color="primary"
                size="medium"
                inputType="button"
                text="REGISTER"
                type="submit"
                onClick={(e) => submit(e)}
                propBackgroundColor={"#367588"}
              />
              }
            </div>
                       
          </div>
        </div>
      </div>
      <div id="register" style={{ float: "center" }}>
          Already have an account? Click here to &nbsp;
          <b id="styleRegister" style={{ cursor: "pointer", color:"white" }} onClick={(e) => backToLogin(e)}>
            Login
          </b>
      </div>
      <div style={{ marginTop: "9vh" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Register;
