import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSalesReport, getExpensesReport, getDisposingReport, getStockingReport, getExpiringReport } from "../api/reportsAPI";


export const salesReport = createAsyncThunk("reports/salesReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getSalesReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const expensesReport = createAsyncThunk("reports/expensesReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getExpensesReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const disposingReport = createAsyncThunk("reports/disposingReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getDisposingReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const stockingReport = createAsyncThunk("reports/stockingReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getStockingReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const expiringReport = createAsyncThunk("reports/expiringReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getExpiringReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const reportsSlice = createSlice({
  name: "common",
  initialState: {
    sales: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    expenses: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    disposing: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    stocking: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    expiring: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
  },

  extraReducers: {
    //sales
    [salesReport.pending]: (state) => {
      state.sales.get.status = "loading";
    },
    [salesReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.sales.get.list = data;
        state.sales.get.error = null;
      }else{
        state.sales.get.error = { message: `${status} - An error occurred` };
      }
      state.sales.get.status = 'idle';
    },
    //expenses
    [expensesReport.pending]: (state) => {
      state.expenses.get.status = "loading";
    },
    [expensesReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.expenses.get.list = data;
        state.expenses.get.error = null;
      }else{
        state.expenses.get.error = { message: `${status} - An error occurred` };
      }
      state.expenses.get.status = 'idle';
    },
    //disposing
    [disposingReport.pending]: (state) => {
      state.disposing.get.status = "loading";
    },
    [disposingReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.disposing.get.list = data;
        state.disposing.get.error = null;
      }else{
        state.disposing.get.error = { message: `${status} - An error occurred` };
      }
      state.disposing.get.status = 'idle';
    },
    //components
    [stockingReport.pending]: (state) => {
      state.stocking.get.status = "loading";
    },
    [stockingReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.stocking.get.list = data;
        state.stocking.get.error = null;
      }else{
        state.stocking.get.error = { message: `${status} - An error occurred` };
      }
      state.stocking.get.status = 'idle';
    },
    //expiring
    [expiringReport.pending]: (state) => {
      state.expiring.get.status = "loading";
    },
    [expiringReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.expiring.get.list = data;
        state.expiring.get.error = null;
      }else{
        state.expiring.get.error = { message: `${status} - An error occurred` };
      }
      state.expiring.get.status = 'idle';
    },
  },
});

export default reportsSlice.reducer;
