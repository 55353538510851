import React, { useEffect,useCallback,useState } from "react";
import statusCards from "../assets/JsonData/status-card-data.json";
import Cards from "../components/cards/Cards";

import Chart from "react-apexcharts";
import Table from "../components/table/Table";
import { Link,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Particles from 'react-particles';
import { loadFull } from "tsparticles";
import shopping from "../assets/images/shopping.svg";
import boxProduct from "../assets/images/boxProduct.svg";
import dawa from "../assets/images/syrups.svg";
import tablet from "../assets/images/tablet.svg";
import syringe from "../assets/images/syringe.svg";
import bottle from "../assets/images/bottle.svg";
import { hasAccess } from "../common/validators";


const availableMenu = [
  {
    icon:"bx bx-dollar-circle",
    subject:"POS",
    url: "/pos"
  },
  {
    icon:"bx bx-receipt",
    subject:"ACCOUNTS",
    url:"/accounts"
  },
  {
    icon:"bx bx-shopping-bag",
    subject:"SALES",
    url:"/sales"
  },
  {
    icon:"bx bx-cart",
    subject:"ITEMS",
    url:"/items"
  },
  {
    icon:"bx bx-user",
    subject:"STAFF",
    url:"/staff"
  },
  {
    icon:"bx bx-chart",
    subject:"REPORTS",
    url:"/reports"
  },
  {
    icon:"bx bx-home",
    subject:"BUSINESSES",
    url:"/businesses"
  }
]



const Dashboard = () => {
  const [userMenu, setUserMenu] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const stats = useSelector((state) => state.common.stats);
  const selectedTheme = useSelector((state) => state.theme.mode);

  const handleClickMenu = (url) => {
    navigate(url);
  }

  useEffect( async () => {
    //dispatch(getStats());
    if(!localStorage.getItem('token')){
      handleClickMenu('/login')
    }else{
      let newUserMenu = []
      for (let index = 0; index < availableMenu.length; index++) {
        //alert(await hasAccess(String(availableMenu[index].url).split('/')[1],'view'));
        if(await hasAccess(String(availableMenu[index].url).split('/')[1],'view') === true){
          newUserMenu.push(availableMenu[index])
        }  
      }
      //alert(JSON.stringify(newUserMenu))
      setUserMenu(newUserMenu);
    }
  }, [dispatch]);



  const particlesInit = useCallback(async (engine) => {
    //console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    //await console.log(container);
  }, []);

// https://rpj.bembi.dev/#images
const myCanvas = <Particles 
id="tsparticles"
init={particlesInit}
loaded={particlesLoaded}
options={{
  background: {
    color: {
      value: "#367588",
      // 
    },
  },
  fpsLimit: 320,
  "particles": {
     
      "line_linked": {
          "enable": true
      },
      links: {
        color: "#367588",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        directions: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 1,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 30,
      },
      opacity: {
        value: 0.5,
      },
      "shape": {
          "type": [
              "image"
          ],
          "image": [
              {
                  "src": dawa,
                  "height": 5,
                  "width": 5
              },
              {
                "src": shopping,
                "height": 80,
                "width": 80
              },
              {
                "src": boxProduct,
                "height": 80,
                "width": 80
              },
              {
                  "src": bottle,
                  "height": 5,
                  "width": 5
              },
              {
                  "src": tablet,
                  "height": 5,
                  "width": 5
              },
              // {
              //   "src": syringe,
              //   "height": 5,
              //   "width": 5
              // }

          ]
      },
      "color": {
          "value": "#ffffff"
      },
      "size": {
          "value": 15,
          "random": false,
          "anim": {
              "enable": true,
              "speed": 4,
              "size_min": 10,
              "sync": false
          }
      }
  },
  "retina_detect": false
}} />


  return (
    <div>
    

    {myCanvas}

      {/* <h2 className="page-header">Dashboard</h2> */}
      <div className="ez-main-card">
        <div className="col-12  col-md-12 col-sm-12">
          {/* <img src={dawa}/> */}
          <div className="row">
              {userMenu.map((item, index) => (              
              <div className="col-4 col-md-6 col-sm-6" key={index} onClick={() => {handleClickMenu(item.url)}} style={{cursor:"pointer"}}>
                <Cards
                  type="normal"
                  icon={item.icon}
                  count={null}
                  title={item.subject}
                />
              </div>
            ))}
          </div>
        </div>
       

      </div>
    </div>
  );
};

export default Dashboard;
