/* eslint-disable no-unused-vars */
import React, { useState,useEffect } from "react";
import "./header.css";
import { useSelector,useDispatch } from "react-redux";

import Profile from "../profile/Profile";
import Theme from "../theme/Theme";
import MyDialog from "../dialog/Dialog";
import uimg from "../../assets/images/user.png";

import user_menu from "../../assets/JsonData/user_menus.json";
import { toast } from "react-toastify";
import Inputs from "../inputs/Inputs";
import BreadCrumbs from "../../common/breadcrumbs";
import { Link,useNavigate } from "react-router-dom";
import {updateUser} from "../../app/userSlice";
import validate from "validate.js";
import { userPasswordSchema } from "../../common/validators";

// const location = useLocation()

const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

const curr_user = {
  institution: userData?.data?.name,
  username: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).fullname : null,
  image: uimg,
};

const Header = () => {
 
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState([]);
  const [instColor, setInstColor] = useState("#367588");

  const dispatch = useDispatch();

  const {auth} = useSelector((state) => state.auth);

  const { error: passwordError, status: passwordStatus } = useSelector(
    ({ users }) => users.put
  );


  useEffect(() => {
    if(!localStorage.getItem('token')){
      handleNavigate('/login')
    }
  }, [auth]);

  //alert(JSON.stringify(auth));
  useEffect(() => {
    
    
  }, [dispatch]);

 

  useEffect(() => {
    if(passwordStatus === "loading" && !passwordError){
      setShowSubmitButton(false)
    }else
    if(passwordStatus === "idle" && !passwordError){
      setShowSubmitButton(true)
      logout(null);
    }

    if(passwordError){
      setShowSubmitButton(true)
    }
    
  }, [passwordStatus,passwordError]);

  const logout = (e) => {
    if(e){e.preventDefault();}
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    //window.location.replace("/login");
    handleNavigate('/login')

  };
  const changePassword = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleRequestClose = () => {
    setOpen(false);
    setErrors([])
    setFormValues({});
  };

  const handleNavigate = (url) => {
    navigate(url);
  }

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };
  const handleSubmit = () => {

    let validationErrors = validate(
      formValues,
      userPasswordSchema
    );

    if(validationErrors){
      setErrors(validationErrors);      
    }else if(formValues.new_password != formValues.confirm_password){
      //userPasswordSchema.confirm_password.presence.message = "New passwords must match"
      let newErrors = {
        ...errors,
        confirm_password: ["New passwords must match"],
      };
      setErrors(newErrors);      
    }else{
      let newUserPassword = {
        current_password:formValues.current_password,
        password:formValues.new_password,
        id:userData.data?.id
      }
      dispatch(updateUser(newUserPassword));
    }

    
  };

  const renderUserToggle = (user) => (
    
    <div className="ez-tright-user">
      {/* <div className="ez-tright-user-img">
        <img src={user.image} alt="user" />
      </div> */}
      {
        String(window.location.pathname).includes("/") && String(window.location.pathname).length < 2 ?
        <div className="ez-tright-username" style={{color:"#eceff1"}}> <b>{user.institution} </b> | {user.username}</div>
        : <div className="ez-tright-username" style={{color:"#367588"}}> <b>{user.institution} </b> | {user.username}</div>
      }
    </div>
  );
  const renderMenuItems = (item, index) => (
    <div
      key={index}
      className="ez-notif-item"
      onClick={(e) => {
        switch (item.action) {
          case "logout":
            logout(e);
            break;
          case "profile":
            toast.success("profile clicked");
            break;
          case "settings":
            changePassword(e);
            break;
          default:
            break;
        }
      }}
    >
      <i className={item.icon}></i>
      <span>{item.content}</span>
    </div>
  );

  const formOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-12">
            <Inputs
              name="current_password"
              size="small"
              label="Current Password"
              value={formValues.current_password}
              inputType="password"
              onChange={handleChange}
              error={errors["current_password"] ? true : false}
              helperText={errors["current_password"] ? errors.current_password[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-12">
            <Inputs
              name="new_password"
              size="small"
              label="New Password"
              value={formValues.new_password}
              inputType="password"
              onChange={handleChange}
              error={errors["new_password"] ? true : false}
              helperText={errors["new_password"] ? errors.new_password[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-12">
            <Inputs
              name="confirm_password"
              size="small"
              label="Repeat New Password"
              value={formValues.confirm_password}
              inputType="password"
              onChange={handleChange}
              error={errors["confirm_password"] ? true : false}
              helperText={errors["confirm_password"] ? errors.confirm_password[0] : null}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="ez-topnav">
      <BreadCrumbs />
      <>
        <div className="ez-top-right">
          <div className="ez-tright-item">
            <Profile customToggle={() => renderUserToggle(curr_user)} />
          </div>
          <div className="ez-tright-item">
            <Profile
              icon="bx bx-user"
              contentData={user_menu}
              renderItems={(item, index) => renderMenuItems(item, index)}
            />
          </div>

          <div className="ez-tright-item">
            
          </div>
          {/* <div className="ez-tright-item">
            <Theme />
          </div> */}
        </div>

        <MyDialog
          title="Change Password"
          width="xs"
          onClose={handleRequestClose}
          open={open}
          submit={handleSubmit}
          text="Save"
          content={() => formOpen()}
          hideSaveButton={!showSubmitButton}
          propBackgroundColor={"#367588"}
        />
      </>
    </div>
  );
};

export default Header;
