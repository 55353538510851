import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import "./inputs.css";
import {
  Autocomplete,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Button,
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { FormLabel, Stack } from "react-bootstrap";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Multiselect from "multiselect-react-dropdown";
import { IMaskInput } from "react-imask";
import moment from "moment/moment";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const Inputs = ({
  size,
  mask,
  value,
  defaultValue,
  options,
  label,
  className,
  list,
  name,
  text,
  btnClass,
  color,
  inputType,
  disabled,
  onChange,
  placeholder,
  onClick,
  listValue,
  minDate,
  listLabel,
  propBackgroundColor,
  error,
  helperText,
  ...others
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return inputType === "text" ? (
    <TextField
      size={size}
      margin="dense"
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled ?? false}
      variant="outlined"
      fullWidth
      error={error}
      helperText={helperText}
      inputProps={{ "data-testid": name+"-input" }}
    />
  ) : inputType === "file" ? (
    <TextField
      type={inputType}
      margin="dense"
      variant="outlined"
      size={size}
      disabled={disabled ?? false}
      name={name}
      label={label}
      onChange={onChange}
      value={value}
      fullWidth
      error={error}
      helperText={helperText}
      inputProps={{ "data-testid": name+"-input" }}
    />
  ) :
   inputType === "number" ? (
    <TextField
      type={inputType}
      margin="dense"
      variant="outlined"
      size={size}
      disabled={disabled ?? false}
      name={name}
      label={label}
      onChange={onChange}
      value={value}
      fullWidth
      error={error}
      helperText={helperText}
      inputProps={{ "data-testid": name+"-input" }}
    />
  ) : inputType === "mask" ? (
    <TextField
      name={name}
      size={size}
      label={label}
      disabled={disabled ?? false}
      value={value}
      margin="dense"
      variant="outlined"
      onChange={onChange}
      InputProps={{
        inputComponent: TextMaskCustom,
      }}
      fullWidth
    />
  ) : inputType === "date" ? (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Stack spacing={3}>
        <DatePicker
          label={label}
          name={name}
          inputFormat="dd/MM/yyyy"
          minDate={minDate}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField {...params} size={size} fullWidth margin="dense"  error={error}
            helperText={helperText}
            inputProps={{ "data-testid": name+"-input" }}
            value={moment(value ? value : new Date()).format('YYYY-MM-DD')} />
          )}
        />
      </Stack>
    </LocalizationProvider>
  ) : inputType === "button" ? (
    <Button
      variant="contained"
      color={color}
      size={size}
      onClick={onClick}
      {...others}
      style={{backgroundColor:propBackgroundColor ? propBackgroundColor:"#455560"}}
    >
      {text}
    </Button>
  ) : inputType === "radio" ? (
    <>
      <FormLabel>{label}</FormLabel>
      <RadioGroup>
        {options
          ? options.map((item, index) => {
              return (
                <FormControlLabel
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                ></FormControlLabel>
              );
            })
          : ""}
      </RadioGroup>
    </>
  ) : inputType === "select" ? (
    <TextField
      select
      name={name}
      label={label}
      margin="dense"
      disabled={disabled ?? false}
      value={value}
      onChange={onChange}
      fullWidth
      size={size}
      error={error}
      helperText={helperText}
      inputProps={{ "data-testid": name+"-input" }}
    >
      {list &&
        list.map((item) => (
          <MenuItem
            key={item[listValue] ?? item.value}
            value={item[listValue] ?? item.value}
          >
            {item[listLabel] ?? item.label}
          </MenuItem>
        ))}
    </TextField>
  ) : inputType === "multi" ? (
    <FormControl sx={{ mt: 2, minWidth: "100%" }}>
      <InputLabel shrink htmlFor="select-multiple-native">
        {label}
      </InputLabel>
      <Select
        style={{
          padding: "2px",
          alignContent: "center",
          justifyContent: "center",
        }}
        defaultValue={defaultValue}
        multiple
        native
        value={value}
        onChange={onChange}
        variant="outlined"
        margin="dense"
        label={label}
        inputProps={{
          id: name,
        }}
        error={error}
        helperText={helperText}
      >
        {list.map((item, index) => (
          <option key={index} value={item[listValue] ?? item.value}>
            {item[listLabel] ?? item.label}
          </option>
        ))}
      </Select>{" "}
    </FormControl>
  ) : inputType === "autocomplete" ? (
    <Autocomplete
      name={name}
      size={size}
      onChange={(event, value) => {
        onChange({
          target: { value: value[listValue] ?? value.item_code, name: name },
        });
      }}
      autoHighlight
      options={options}
      getOptionLabel={(option) => option[listLabel] ?? option.label}
      renderOption={(props, option) => (
        <div {...props}>{option[listLabel] ?? option.label}</div>
      )}
      renderInput={(params) => (
        <TextField
          margin="dense"
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "item_code",
          }}
        />
      )}
    />
  ) : inputType === "multiselect" ? (
    <Multiselect
      placeholder={placeholder}
      options={options}
      displayValue={label}
      onSelect={onChange}
    />
  ) : inputType === "textarea" ? (
    <TextField
      label={label}
      className={className}
      name={name}
      size={size}
      multiline
      onChange={onChange}
      value={value}
      variant="outlined"
      margin="dense"
      fullWidth
      error={error}
      helperText={helperText}
      inputProps={{ "data-testid": name+"-input" }}
    />
  ) : inputType === "password" ? (
    <>
      <TextField
        margin="dense"
        type={showPassword ? "text" : inputType}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        size={size}
        variant="outlined"
        fullWidth
        error={error}
        helperText={helperText}
        inputProps={{ "data-testid": name+"-input" }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className="pass"
              position="end"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? (
                <i className="bx bx-low-vision"></i>
              ) : (
                <i className="bx bx-show-alt"></i>
              )}
            </InputAdornment>
          ),
          autoComplete: "new-password",
        }}
      />
    </>
  ) : (
    ""
  );
};

export default Inputs;
